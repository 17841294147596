import React, { useState } from "react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimezoneSelect from "react-timezone-select";
// import { EXCHANGE_URLS_BUYER } from "../Important/URLS";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { EXCHANGE_URLS_BUYER } from "../../Important/URLS";

export const Section6 = () => {
  const theme = useTheme();
  const [errors, setErrors] = useState({});
  const [personName, setPersonName] = React.useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [inputData, setInputData] = useState({
    time_zone: "",
    date_time: new Date(),
    name: "",
    mobile_number: "",
    email: "",
    services: [],
    comment: "",
  });

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    time_zone: Yup.string().required("Time Zone is Required"),
    date_time: Yup.date()
      .required("Date & Time is Required")
      .min(new Date(), "Date must be in the future"),
    name: Yup.string().required("Name is Required"),
    email: Yup.string()
      .required("Email is Required")
      .email("Invalid email format"),
    services: Yup.array().min(1, "At least one service is required"),
    mobile_number: Yup.string()
      .matches(/^\d{10}$/, "Phone Number must be 10 digits")
      .required("Phone Number is required"),
      comment: Yup.string().optional(),
  });

  const names = [
    "Optimization (SEO)",
    "Social Media",
    "Application Development",
    "Shopify API Development",
    "Web Design",
    "ECommerce Optimization",
    "Website Development",
    "WordPress Plugin",
    "Pay Per Click (PPC)",
    "Shopify Development",
    "Custom Website Development",
    "Ecommerce Website Development",
  ];

  const handleDateChange = (date) => {
    setStartDate(date);
    setInputData((prevData) => ({ ...prevData, date_time: date }));
  };

  const handleServiceChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setInputData((prevData) => ({ ...prevData, services: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(inputData, { abortEarly: false });
      console.log("Form Submitted", inputData);

      // If validation passes, you can proceed to submit the form
      const response = await axios.post(
        `${EXCHANGE_URLS_BUYER}/booking`,
        inputData
      );
      if (response.status === 200) {
        console.log("Success:", response.data);
        toast.success("Thank You Submitting");
        navigate("/");
        // Handle success (e.g., show notification, clear form, etc.)
      }
    } catch (validationErrors) {
      const errors = validationErrors?.inner?.reduce((acc, currentError) => {
        acc[currentError.path] = currentError.message;
        return acc;
      }, {});
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone?.value);
    setInputData((prevData) => ({ ...prevData, time_zone: timezone?.value }));
  };

  const getStyles = (name, personName, theme) => {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Root>
      <div id="form">
      <h2   className="first-content">
        Book
        <span style={{ color: "#0b66c2" }}> an </span> Appointment
      </h2>

      <form onSubmit={handleFormSubmit}>
        <div className="time_heading">
          <span>Select Date & Time</span>
        </div>

        <div className="cal_div">
          <div className="select-wrapper">
            <TimezoneSelect
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              name="time_zone"
            />
            {errors?.time_zone && <p>{errors.time_zone}</p>}
          </div>

          <div>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="Pp"
              className="cale_time"
              name="date_time"
            />
            {errors?.date_time && <p>{errors.date_time}</p>}
          </div>
        </div>

        <div className="form_div">
          <div className="container-fluid">
            <div className="row  fiel_div">
              <div className="col-lg-6 col-md-12 col-12">
                <input
                  type="text"
                  placeholder="Name*"
                  name="name"
                  value={inputData.name}
                  onChange={handleInputChange}
                  required
                />
                {errors?.name && <p>{errors.name}</p>}
              </div>

              <div className="col-lg-6 col-md-12 col-12">
                <input
                  type="tel"
                  placeholder="Phone*"
                  name="mobile_number"
                  value={inputData.mobile_number}
                  onChange={handleInputChange}
                  required
                />
                {errors?.mobile_number && <p>{errors.mobile_number}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-6 col-md-12 col-12">
                <input
                  type="email"
                  placeholder="Email Address*"
                  name="email"
                  value={inputData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors?.email && <p>{errors.email}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-12 col-md-12 col-12">
                <FormControl className="serv_div" fullWidth>
                  <Select
                    multiple
                    displayEmpty
                    value={personName}
                    onChange={handleServiceChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Services</em>;
                      }
                      return selected.join(", ");
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                    }}
                    inputProps={{ "aria-label": "Select services" }}
                  >
                    <MenuItem disabled value="">
                      <em>Services</em>
                    </MenuItem>
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.services && <p>{errors.services}</p>}
                </FormControl>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-12">
                <label className="label_heading">
                  Share Project Details(Optional)
                </label>
                <textarea
                  name="comment"
                  rows="5"
                  cols="75"
                  value={inputData.comment}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="sub_btn">
                  <button type="submit">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
    </Root>
  );
};

const Root = styled.section`
  padding: 2% 10%;

  .first-content {
    font-size: 45px;
    font-weight: bold;
    line-height: 1.3em;
    margin-bottom: 20px;
    word-spacing: 5px;
    text-align: center;
  }
  .time_heading {
    display: flex;
    justify-content: center;
    span {
      font-size: 19px;
      font-weight: 600;
      line-height: 1.3em;
    }
  }
  .cal_div {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
    input.cale_time {
      padding: 10px;
      border-radius: 5px;
      background-color: #f3f3f3;
      border: 1px solid #dadada;
      color: #6b7996;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: #6b7996;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 0.3rem;
      background-color: #13c9df;
      color: #fff;
    }

    .css-1fdsijx-ValueContainer {
      padding: 5px 8px;
    }
    .css-13cymwt-control {
      margin-top: 1px;
      /* width: 225px; */
      background-color: #f3f3f3;
    }

    .css-qbdosj-Input {
      color: #6b7996 !important;
    }

    .mon_head {
      font-size: 20px;
      font-weight: 500;
    }
    .main {
      width: 20rem;
      padding: 0.7rem;
      margin-left: 1rem;
      border: 1px solid #dadada;
    }
    .header {
      display: flex;
      margin-bottom: 0.7rem;
      justify-content: space-between;
      align-items: center;
    }
    .header button {
      font-size: 1.4rem;
      cursor: pointer;
      color: #13c9df !important;
      border: 1px solid transparent !important;
    }
    .body {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 5px;
    }
    .box {
      height: 2rem;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .box.empty {
      background-color: #e0e0e0;
      cursor: default;
    }
    .box.selected {
      background-color: #13c9df;
      color: white;
    }
  }

  .form_div {
    input[type="text"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
    }

    input[type="text"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
    }

    input[type="tel"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    input[type="email"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    textarea {
      width: 100%;
      background: #f3f3f3;
      border: 1px solid #dadada;
    }
    .sub_btn {
      display: flex;
      justify-content: center;
      button {
        font-size: 14px;
        color: white;
        text-transform: uppercase;
        padding: 13px 81px;
        border: 0;
        letter-spacing: 3px;
        border-radius: 50px;
        background-image: linear-gradient(101deg, #012d64, #06b0ff);
        box-shadow: 0 10px 20px #0586ceb3;
      }
    }

    .serv_div {
      width: 100%;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      background: #f3f3f3;
    }
  }

  @media (max-width: 567px) {
    padding: 2% 5%;
    .first-content {
      font-size: 35px;
      text-align: center;
    }

    .fiel_div {
      display: flex;
      gap: 20px;
    }

    .cal_div {
      flex-direction: column;
    }
    .cal_div input.cale_time {
      width: 100%;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .fiel_div {
      display: flex;
      gap: 20px;
    }
  }
`;
