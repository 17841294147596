import React from "react";
import "./policies.css";
export const PrivacyPolicy = () => {
  return (
    <div className="container-fluid">
      <div className="row bg-color">
        <div className="col-lg-12 col-md-12 col-12">
          <h1>Privacy Policy :</h1>
          <p>
            This Privacy Policy was last updated on July 28, 2013. Graphics,
            Typ, and Color Enterprises, Inc. (herein referred to as the “Phanom
            Professionals,” “we,” “us” or “our“) provides and makes available
            websites located at www.phanomprofessionals.com (the “Sites“ or
            “Site”). On our Sites, we provide products, services, information,
            promotions, and tools (collectively the “Service”) to our Site
            visitors (“you” or “your”). Your privacy is very important to us. We
            developed this Privacy Policy to make sure you know what information
            we collect and how we use it. By using our Sites and the Service,
            you expressly consent to the collection, storage, disclosure, and
            use of your information as we have outlined in this Privacy Policy
            and you agree to the terms and conditions of this Privacy Policy as
            well as our Terms of Use linked in the Sites, and incorporated
            herein by reference. We reserve the right, at our sole discretion,
            to modify or replace any part of this Privacy Policy from time to
            time without prior notice. We may also, in the future, offer new
            services and/or features through the Sites or Services (including,
            the release of new tools and resources). Such new services and
            features shall be subject to the terms and conditions of the
            then-current Privacy Policy. It is your responsibility to check this
            Privacy Policy periodically for changes. You may read a current,
            effective copy of this Privacy Policy at any time by selecting the
            “Privacy Policy” link on the Sites. The revised terms and conditions
            will become effective at the time of posting. Your continued use of
            or access to the Sites or Service following the posting of any
            changes to this Privacy Policy constitutes acceptance of those
            changes. If any change to this Privacy Policy is not acceptable to
            you, your sole remedy is to cease accessing, browsing and otherwise
            using the Site or Service.
          </p>
          <h5>A. COLLECTION OF INFORMATION :</h5>
          <p>
            ⦁ Website Visitors: We collect certain non-personally identifiable
            information through the use of web beacons, log files, or various
            other technologies. Such information may include your IP address,
            browser type, site usage, whether an email from us was opened, and
            other information that cannot presently be used to specifically
            identify you. Our purpose in collecting non-personally identifying
            information is to better understand how our visitors use the Sites
            and Services. This non-personally identifiable information may be
            compiled and analyzed on an aggregate basis, and we may share this
            aggregate data with our affiliates, agents, and business partners,
            or with other third parties for other lawful purposes.
          </p>
          <p>
            ⦁ Cookies: A cookie is a string of information that a website stores
            on a visitor’s computer, and that the visitor’s browser provides to
            the website each time the visitor returns. We use cookies to help us
            identify and track visitors, their usage of our Sites and Services,
            and their website access preferences. You can delete cookies or
            prevent cookies from being stored on your computer by setting your
            browser to refuse cookies or to display a message when a cookie is
            being sent, with the drawback that certain features of our Sites and
            Service may not function properly without the aid of cookies. For
            more information on how your browser handles cookies, please refer
            to your particular browser’s help section or other documentation.
          </p>
          <p>
            ⦁ Ads/ Promotions: Ads or promotions appearing on any of our Sites
            may be delivered to users by advertising partners, who may set
            cookies. These cookies allow the ad server to recognize your
            computer each time they send you an online advertisement to compile
            information about you or others who use your computer. This
            information allows ad networks to, among other things, deliver
            targeted advertisements that they believe will be of most interest
            to you. This Privacy Policy covers the use of cookies by us and does
            not cover the use of cookies by any advertisers.
          </p>
          <p>
            ⦁ Information You Provide: You may provide us with certain
            personally identifiable information when you:
            <br></br> ⦁ register with a
            Site;<br></br> ⦁ order products or gift certificates;<br></br> ⦁ use the Services; <br></br>⦁
            request customer support or send us any other communication;<br></br> ⦁ sign
            up for newsletters or email alerts;<br></br> ⦁ sign up for or redeem special
            offers, sweepstakes or contests; and/or <br></br> ⦁ respond to surveys.
          </p>
          <p>
            Such information may include your name, email address, billing
            address, shipping address, financial information, or other
            information relevant to Services, special offers, sweepstakes,
            contests, and/or customer surveys. You may also provide us with
            information about other people, such as their email addresses if you
            email a product to a friend or refer a friend to the Sites,
            Services, or promotions. We do not disclose personally identifiable
            information other than as described below. You may also choose not
            to provide us with certain information, but that may result in you
            being unable to use certain features of our Sites or Services
            because such information may be required for you to register with a
            Site; purchase products or services; use Services, participate in a
            contest, promotion, survey, or sweepstakes; or initiate other
            transactions or communications with us.
          </p>
          <p>
            ⦁ Comments/Submissions: Through the Sites and Service, you will have
            the ability to upload content, post and share information about
            yourself and others, communicate with others, and post comments or
            reviews of content found on or through the Service (collectively,
            “Your Submissions“). Please be aware that whenever you voluntarily
            disclose personally identifiable information on publicly viewable
            web pages, that information will be publicly available and can be
            collected and used by others. For example, if you post your email
            address, you may receive unsolicited messages. We cannot control who
            views Your Submissions or what other users may do with the
            information you voluntarily submit, so we encourage you to exercise
            discretion and caution with respect to your personally identifiable
            information. Once you have posted information, you may not be able
            to edit or delete such information. We are not responsible for any
            personally identifiable information included in Your Submissions.
          </p>
          <h5>B. USE OF INFORMATION :</h5>
          <h6>
            We use the personally identifiable information you provide to:
          </h6>
          <p>
            ⦁ provide and improve the content, features, and functionality of
            the Sites and Services;
            <br></br>⦁ improve our products and services;
            <br></br>⦁ better understand our users;
            <br></br>⦁ send you information about our products and services;
            <br></br>⦁ fulfill your requests for our products and services;
            <br></br>⦁ communicate with you about the status of your orders
            <br></br>⦁ respond to inquiries
            <br></br>⦁ provide notices about the Sites or Services
            <br></br>⦁ notify you of the results of sweepstakes, contests, and
            other promotions;
            <br></br>⦁ support our internal business purposes, such as data
            analysis, audits, and so forth; and/or
            <br></br>⦁ communicate with you about your account.
          </p>
          <p>
            We use non-personally identifiable information to monitor the use
            and performance of the Site so that we can learn more about how
            people use the Site which helps us improve our products and services
            and significantly enhance your experience on our website. If you
            send us a request (for example via a support email or via one of our
            feedback mechanisms), we reserve the right to publish it in order to
            help us clarify or respond to your request or to help us support
            other users.
          </p>
        </div>

        <h5>C. INFORMATION SECURITY :</h5>
        <p>
          We use commercially reasonable and generally accepted technological
          safeguards to protect any information we collect about you. The secure
          server software (SSL) we use encrypts all information you input before
          it is sent to us. In addition, your account information on our Sites
          is password-protected for your privacy and security. You should always
          take steps to protect the confidentiality of the password you select.
          It is important for you to protect against unauthorized access to your
          password and to your computer. We work very hard to protect your
          privacy, but we do not promise, and you should not expect, that your
          personally identifiable information or private communications will
          always remain private. While we use industry-standard precautions to
          safeguard your personally identifiable information, we cannot
          guarantee complete security. Please keep this in mind when disclosing
          any Personal Data to us through our Sites and Services. Your personal
          data will be processed by us in the United States where data
          protection and privacy regulations may not offer the same level of
          protection as in other parts of the world, such as the European Union.
          If you create a user account with us, you agree to this Privacy Policy
          and you consent to the transfer of all information you provide to us
          in India.
        </p>
      </div>
    </div>
  );
};
